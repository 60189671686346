.user-profile {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-info img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-info h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.profile-info p {
  font-size: 18px;
  margin-bottom: 5px;
}

.profile-info button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.profile-info button:hover {
  background-color: #0056b3;
}

form {
  display: flex;
  flex-direction: column;
}

form label {
  margin-bottom: 20px;
}

form input,
form textarea {
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

form button {
  margin-top: 10px;
}

form button[type="submit"] {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

form button[type="submit"]:hover {
  background-color: #0056b3;
}

form button[type="button"] {
  background-color: #ff8c00;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

form button[type="button"]:hover {
  background-color: #bb6a07;
}
