.problem-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
}

.problem-details {
  width: 100%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.problem-details h2 {
  color: black;
}

.problem-details button.my-submissions-btn {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
}

.problem-details h2 {
  margin-top: 0;
}

.problem-details p {
  line-height: 1.6;
}

.problem-details img {
  max-width: 100%;
  margin: 10px 0;
}

.examples {
  margin-top: 20px;
}

.language-select {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.language-select label {
  margin-right: 10px;
}

.language-select select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.tab-container {
  margin-top: 20px;
}

.tab-buttons {
  display: flex;
  justify-content: space-between;
}

.tab-buttons button {
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.tab-buttons button.active {
  background-color: #007bff;
  color: white;
}

.tab-content {
  margin-top: 20px;
}

.output-tab {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 14px;
  font-family: "Courier New", Courier, monospace;
  background-color: white;
  color: black;
  border: 1px solid black;
  margin-bottom: 10px;
  text-align: left;
  min-height: 74px;
  overflow: auto;
}

.code-submission {
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.code-submission textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  font-family: "Courier New", Courier, monospace;
}

.code-submission textarea::placeholder {
  color: #bbb;
}

.output {
  margin-top: 20px;
}

.output pre {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.run-submit-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.run-submit-buttons button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border-radius: 5px;
}

.test-case-results {
  margin-top: 20px;
}

.test-case-buttons {
  display: flex;
  flex-wrap: wrap;
}

.test-case-button {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.test-case-button.passed {
  background-color: #4caf50;
  color: white;
}

.test-case-button.failed {
  background-color: #f44336;
  color: white;
}

.test-case-button.not-tested {
  background-color: #ffc107;
  color: white;
}

@media (max-width: 1000px) {
  .problem-container {
    flex-direction: column;
  }

  .tab-buttons {
    flex-direction: column;
  }

  .tab-buttons button {
    margin-bottom: 5px;
  }
}
