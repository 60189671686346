.problems-container {
  background-color: #1e1e1e;
  color: #d4d4d4;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  color: #d4d4d4;
  text-align: center;
  margin-bottom: 20px;
}

.create-problem-button {
  background-color: #007acc;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.create-problem-button:hover {
  background-color: #005f99;
}

.problems-table {
  width: 100%;
  border-collapse: collapse;
}

.problems-table th,
.problems-table td {
  border: 1px solid #3c3c3c;
  padding: 12px;
  text-align: left;
}

.problems-table th {
  background-color: #333333;
  color: #d4d4d4;
}

.problems-table td {
  background-color: #252526;
  color: #d4d4d4;
}

.problems-table tr:hover {
  background-color: #2a2d2e;
}

.problems-table a {
  color: #569cd6;
  text-decoration: none;
}

.problems-table a:hover {
  text-decoration: underline;
}

.problems-table .easy {
  color: #00ff00;
}

.problems-table .medium {
  color: #ffcc00;
}

.problems-table .hard {
  color: #ff0000;
}
