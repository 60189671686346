.background {
  background: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  height: auto;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.signup-container {
  max-width: 400px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin: 100px;
}

.signup-container h2 {
  font-size: 2rem;
  margin-top: 0px;
  font-weight: bold;
}

.signup-container img {
  width: 50px;
  height: 50px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.signup-container form {
  display: flex;
  flex-direction: column;
}

.signup-container label {
  font-size: 1rem;
  margin-bottom: 5px;
  text-align: left;
}

.signup-container input,
.signup-container select {
  padding: 10px;
  margin-bottom: 20px;
  margin-up: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 90%;
}

.signup-container button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.signup-container button:hover {
  background: #0056b3;
}

.signup-container button:focus {
  outline: none;
}

.login-button {
  margin-top: 20px;
}

.login-button a {
  color: #007bff;
  text-decoration: none;
}

.login-button a:hover {
  text-decoration: underline;
}

.signup-container .password-container {
  position: relative;
}

.signup-container .password-container input {
  padding-right: 40px;
}

.signup-container .eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 1.2rem;
}
