.submissions-container {
  background-color: #1e1e1e;
  color: #d4d4d4;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  color: #d4d4d4;
  text-align: center;
  margin-bottom: 20px;
}

.submissions-table {
  width: 100%;
  border-collapse: collapse;
}

.submissions-table th,
.submissions-table td {
  border: 1px solid #3c3c3c;
  padding: 12px;
  text-align: left;
}

.submissions-table th {
  background-color: #333333;
  color: #d4d4d4;
}

.submissions-table td {
  background-color: #252526;
  color: #d4d4d4;
}

.submissions-table tr:hover {
  background-color: #2a2d2e;
}

.accepted-row td {
  color: #8fd19e;
}

.rejected-row td {
  color: #ed969e;
}

button {
  background-color: #007acc;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

button:hover {
  background-color: #005f99;
}

.modal-show {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #1e1e1e;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  color: #d4d4d4;
  text-align: left;
}

.modal-content pre {
  text-align: left;
}

.modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-close:hover,
.modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
