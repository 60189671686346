body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}

.main-heading {
  font-size: 2.5rem;
  margin: 20px 0;
}

.home-page {
  background: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.welcome-container {
  max-width: 600px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.welcome-container h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.welcome-container p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.welcome-container button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.welcome-container button:hover {
  background-color: #0056b3;
}

.welcome-container button:focus {
  outline: none;
}
