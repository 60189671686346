.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  border-bottom: 2px solid #fda085;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.navbar-logo img {
  height: 60px;
}

.navbar-links {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.navbar-item {
  position: relative;
  margin-left: 20px;
}

.navbar-link {
  cursor: pointer;
}

.navbar-right {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
}

.navbar-user {
  position: relative;
}

.navbar-user-icon {
  cursor: pointer;
}

.navbar-user-icon img {
  height: 40px;
}

.navbar-dropdown {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 150px;
  box-sizing: border-box;
  z-index: 1001;
  right: 0;
}

.navbar-dropdown a,
.navbar-dropdown button {
  text-decoration: none;
  color: #000;
  background: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  text-align: left;
  display: block;
  width: 100%;
}

.navbar-dropdown a:hover,
.navbar-dropdown button:hover {
  background-color: #f0f0f0;
}
